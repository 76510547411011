.propertiesHero {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 90vh;
    width: 100%;
    color: #F3F3F3;

    @media screen and (min-width: 1800px) {
        height: 80vh;
    }


    @media screen and (max-width: 1100px) {
        height: 50vh;
    }

    @media screen and (max-width: 900px) {
        height: 80vh;
        flex-direction: column-reverse;
        overflow: hidden;
    }
}

.propertiesHeroTextPanel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    z-index: 3;
    width: 50%;
    height: 80%;
    position: absolute;
    border-right: 25px solid #F3F3F3;
    border-top: 25px solid #F3F3F3;
    background-color: #040145;
    rotate: 45deg;
    transform: skew(20deg, 20deg);
    translate: -100px -80px;

    @media screen and (min-width: 1800px) {
        translate: -200px -60px;
    }

    @media screen and (max-width: 1500px) {
        translate: -30px -70px;
    }

    @media screen and (max-width: 1000px) {
        position: relative;
        rotate: 0deg;
        transform: skew(0deg, 0deg);
        translate: 0px 0px;
        border: none;
        width: 100%;
        max-width: 100%;
        text-align: center;
        padding: 0px 30px;
    }
}

.propertiesHeroText {
    position: absolute;
    font-size: 23px;
    padding: 0px 40px;
    z-index: 5;

    @media screen and (min-width: 1800px) {
        font-size: 27px;
    }

    @media screen and (min-width: 2000px) {
        font-size: 33px;
        padding: 0px 70px;
    }

    @media screen and (max-width: 900px) {
        translate: 0px -180px;
        line-height: 35px;
        text-align: center;
    }

    @media screen and (max-width: 600px) {
        translate: 0px -110px;
    }
}

.propertiesHeroTextBold {
    font-weight: 600;
}

.propertiesHeroImage {
    background-image: url('../images/properties.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    height: 100%;
    min-height: 40px;
    width: 100%;
    min-width: 40px;
}

.propertiesHeroSpacer {
    width: 25%;
    height: 100%;
    background-color: #040145;

    @media screen and (max-width: 1000px) {
        display: none;
    }
}

.properties {
    background-color: #F3F3F3;
    position: relative;
    z-index: 10;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 100px 170px;

    @media screen and (min-width: 2000px) {
        grid-template-columns: 1fr 1fr 1fr;
        padding: 200px 170px;
    }

    @media screen and (max-width: 1300px) {
        padding: 100px 10px;
    }

    @media screen and (max-width: 900px) {
        grid-template-columns: 1fr;
        padding: 100px 0px;
    }
}

.property {
    width: 450px;
    margin: auto;
    height: 480px;
    background-color: hsl(33, 16%, 73%);
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;

    @media screen and (min-width: 2000px) {
        margin-bottom: 100px;
        width: 510px;
        height: 550px;
    }

    @media screen and (max-width: 600px) {
        width: 350px;
        height: 400px;
    }
}

.property:hover {
    background-color: #e8e5df;
}

.property>img {
    width: 100%;
    height: 300px;

    @media screen and (max-width: 600px) {
        height: 220px;
    }
}

.propertyText {
    display: flex;
    flex-direction: column;
    height: 28%;
    align-items: center;
    justify-content: center;
    font-size: 23px;
    line-height: 33px;
    color: #040145;
}

.propertyTextLink {
    text-decoration: none;
    color: #040145;
    font-weight: 600;
    letter-spacing: 1.5px;
    font-family: "Montserrat", sans-serif;
}