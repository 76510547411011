.navContainer {
    display: flex;
    flex-direction: column;
}

.navbar {
    background-color: rgb(243, 243, 243, .7);
    height: 80px;
    width: 100%;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
    transition: 1s ease;

    @media screen and (max-width: 600px) {
        display: none;
    }
}

.navLogoLink {
    height: 60%;
    margin-left: 50px;

    @media screen and (max-width: 600px) {
        margin: 0;
    }
}

.navlogo {
    max-height: 100%;

    @media screen and (max-width: 600px) {
        max-height: 70px;
        max-width: 100px;
        margin: 0;
    }
}

.navlinks {
    padding: 0px 50px;
}

.navlinks>* {
    text-decoration: none;
    padding: 0px 30px;
    color: #040145;
    opacity: 100%;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 500;

    @media screen and (min-width: 1800px) {
        font-size: 26px;
    }
}

.navbar:last-child {
    margin-right: 40px;
}

.hideNav {
    transform: translateY(-100%)
}

.mobileNavButton {
    height: 40px;
    width: 40px;
    position: fixed;
    top: 15px;
    right: 15px;
    border-radius: 5px;
    background-color: white;
    border: 1px solid #040145;
    z-index: 10;
    justify-content: center;
    align-items: center;
    color: #040145;
    display: none;


    @media screen and (max-width: 600px) {
        display: flex;
    }
}

.mobileNavMenu {
    position: fixed;
    width: 50%;
    height: 100vh;
    right: 0;
    top: 0;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    gap: 20px;
    z-index: 9;
    padding: 100px 40px;
    align-items: flex-end;
    box-sizing: border-box;
    transform: translateX(100%);
    transition: transform 0.5s ease;
}

.mobileNavMenu.show {
    transform: translateX(0);
}

.mobileNavMenu>* {
    text-decoration: none;
    color: #040145;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 600;
}


.footer {
    background-color: #e0dede;
    height: 200px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 60px;

    @media screen and (max-width: 600px) {
        flex-direction: column-reverse;
        padding: 30px;
        gap: 40px;
        height: fit-content;
        width: 85%;
    }
}

.footer-links {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-left: 30%;

    @media screen and (max-width: 1100px) {
        margin: 0;
    }
}

.footer-links>* {
    font-family: "Montserrat", sans-serif;
    text-decoration: none;
    color: #040145;
}

.contact {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-right: 10%;

    @media screen and (max-width: 600px) {
        padding: 0;
    }
}

.contact>* {
    font-family: "Montserrat", sans-serif;
    color: #040145;
}