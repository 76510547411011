@import url('https://fonts.googleapis.com/css2?family=Allura&display=swap');

.about-hero-image {
    background-image: url('../images/about.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    width: 100%;
    height: 870px;
    position: relative;

    @media screen and (min-width: 1900px) {
        height: 1300px;
    }

    @media screen and (max-width: 900px) {
        height: 400px;
    }

    @media screen and (max-width: 600px) {
        height: 200px;
    }
}

.about-hero-text {
    background-color: #fff7f3;
    color: #040145;
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: right;
    font-size: 20px;
    line-height: 40px;
    position: absolute;
    width: 50%;
    padding: 30px;
    right: 30px;
    top: 35%;
    opacity: 90%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;


    @media screen and (min-width: 1900px) {
        font-size: 25px;
        line-height: 50px;
        margin: 200px 100px;
    }

    @media screen and (max-width: 900px) {
        margin: 100px 50px;
    }

    @media screen and (max-width: 900px) {
        display: none;
    }
}

.about-hero-text>h3 {
    margin-top: 40px;
    font-size: 40px;
    font-family: "Allura", cursive;

    @media screen and (min-width: 1800px) {
        font-size: 48px;
    }
}

.about-hero-text>h6 {
    font-style: italic;
}

.coloredText {
    color: #a14329;
    font-weight: 700;
}

.about-hero-text-mobile {
    display: none;

    @media screen and (max-width: 900px) {
        background-color: #fff7f3;
        color: #040145;
        display: flex;
        flex-direction: column;
        font-size: 13px;
        line-height: 35px;
        position: absolute;
        padding: 30px;
        box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
    }
}

.about-hero-text-mobile>h3 {
    font-family: "Allura", cursive;
    font-size: 20px;
    margin-top: 20px;
}

.about-hero-text-mobile>h6 {
    font-style: italic;
}

.ourRoots {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #040145;
    color: #f3f3f3;
    padding: 130px;

    @media screen and (max-width: 900px) {
        padding: 50px;
    }

    @media screen and (max-width: 600px) {
        padding: 50px 20px;
    }
}

.ourRoots>h1 {
    font-size: 60px;
    margin-bottom: 45px;

    @media screen and (min-width: 1800px) {
        font-size: 70px;
    }

    @media screen and (max-width: 600px) {
        font-size: 40px;
    }
}

.ourRoots>h2 {
    font-size: 25px;
    font-weight: 400;
    line-height: 38px;

    @media screen and (min-width: 1800px) {
        font-size: 32px;
        line-height: 47px;
    }

    @media screen and (max-width: 600px) {
        font-size: 20px;
        line-height: 32px;
    }
}

#ourRootsBoldText {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 130px;

    @media screen and (min-width: 1800px) {
        font-size: 36px;
    }

    @media screen and (max-width: 600px) {
        font-size: 24px;
        margin-top: 15px;
    }
}

.rootSections {
    display: flex;
    flex-direction: column;
    gap: 70px;

    @media screen and (min-width: 1800px) {
        gap: 120px;
    }
}

.rootSectionEven {
    text-align: left;
    margin-right: 40%;
    border-bottom: 1px solid #F3F3F3;
    border-right: 1px solid #F3F3F3;

    @media screen and (max-width: 600px) {
        margin: 0px;
    }
}

.rootSectionOdd {
    text-align: right;
    margin-left: 40%;

    @media screen and (max-width: 600px) {
        margin: 0px;
    }
}

.rootSectionEven>h3 {
    color: #cfcfcf;
    font-weight: 400;
    font-size: 45px;
    text-align: left;

    @media screen and (min-width: 1800px) {
        font-size: 50px;
    }
}

.rootSectionOdd>h3 {
    color: #cfcfcf;
    font-weight: 400;
    font-size: 45px;
    text-align: right;

    @media screen and (min-width: 1800px) {
        font-size: 50px;
    }
}

.rootSectionEven>h3>span {
    font-weight: 700;
}

.rootSectionOdd>h3>span {
    font-weight: 700;
}

.rootSectionSubtitle {
    font-family: "Montserrat", sans-serif;
    font-size: 22px;

    @media screen and (min-width: 1800px) {
        font-size: 26px;
    }
}

.rootSectionOdd>.rootSectionBody {
    font-size: 18px;
    padding: 25px 0px 50px 50px;
    border-bottom: 1px solid #F3F3F3;
    border-left: 1px solid #F3F3F3;
    font-family: "Montserrat", sans-serif;
    line-height: 24px;

    @media screen and (min-width: 1800px) {
        font-size: 23px;
        line-height: 28px;
    }
}

.rootSectionEven>.rootSectionBody {
    font-size: 18px;
    padding: 25px 50px 50px 0px;
    font-family: "Montserrat", sans-serif;
    line-height: 24px;

    @media screen and (min-width: 1800px) {
        font-size: 23px;
        line-height: 28px;
    }
}

.thimble {
    background-color: #F3F3F3;
    height: 650px;
    display: flex;
    padding: 150px 100px;

    @media screen and (min-width: 1800px) {
        height: 750px;
    }

    @media screen and (max-width: 600px) {
        flex-direction: column;
        padding: 0px;
        height: auto;
    }
}

.thimbleImg {
    min-width: 400px;
    height: 650px;
    border: 3px solid #FFFFFF;
    box-shadow: 0 0 20px hsl(33, 16%, 73%);

    @media screen and (min-width: 1800px) {
        height: 900px;
        min-width: 600px;
    }

    @media screen and (max-width: 900px) {
        min-width: auto;
        height: 300px;
    }

    @media screen and (max-width: 600px) {
        min-width: 100%;
        height: auto;
    }
}

.thimbleText {
    background-color: #a14329;
    min-width: 600px;
    height: fit-content;
    margin-top: 125px;
    margin-left: -25px;
    box-shadow: 0px -25px 20px -20px hsl(33, 16%, 73%), 0px 25px 20px -20px hsl(33, 16%, 73%);
    color: #FFFFFF;
    text-align: left;
    font-size: 35px;
    padding: 40px;
    line-height: 65px;

    @media screen and (min-width: 1800px) {
        width: 900px;
        min-height: 400px;
        font-size: 40px;
        padding: 40px;
    }

    @media screen and (max-width: 1100px) {
        font-size: 26px;
        min-width: 0px;
    }

    @media screen and (max-width: 600px) {
        margin: 0px;
        min-width: 100px;
        max-width: 100%;
        height: auto;
        font-size: 28px;
        box-sizing: border-box;
    }
}

.thimbleParagraph {
    line-height: 40px;
    margin-top: 60px;
    font-size: 25px;
    font-family: "Play", sans-serif;

    @media screen and (min-width: 1800px) {
        font-size: 32px;
    }
}

.missionTitle {
    font-size: 25px;
    text-align: center;
    background-color: #F3F3F3;
    color: #040145;
    padding-top: 50px;

    @media screen and (min-width: 1800px) {
        padding-top: 150px;
    }
}

.mission {
    padding: 20px 80px 80px 80px;
    background-color: #F3F3F3;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 900px) {
        padding: 20px;
    }
}

.missionGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 400px 400px;
    gap: 10px;
    color: #F3F3F3;

    @media screen and (min-width: 1800px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 400px;
    }

    @media screen and (max-width: 600px) {
        grid-template-columns: 1fr;
        grid-template-rows: 400px 400px 400px 400px;
    }
}

.missionGrid>div {
    padding: 40px;
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 25px;
    letter-spacing: 3px;
    line-height: 35px;

    @media screen and (min-width: 1800px) {
        font-size: 32px;
        letter-spacing: 4px;
    }
}

.missionGrid>:first-child {
    background-color: #040145;
}

.missionGrid>:nth-child(2) {
    background-color: #164570;
}

.missionGrid>:nth-child(3) {
    background-color: #11538f;
}

.missionGrid>:nth-child(4) {
    background-color: #0b4378;
}

.circleDesign {
    height: 60px;
    width: 60px;
    background-color: #F3F3F3;
    position: absolute;
    border-radius: 50%;

    @media screen and (min-width: 1800px) {
        display: none;
    }

    @media screen and (max-width: 600px) {
        display: none;
    }
}