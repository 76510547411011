@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

* {
    font-family: "Libre Baskerville", serif;
    margin: 0;
    padding: 0;
}

.bgImage {
    background-image: url('../images/hero.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
    position: fixed;
    height: 100%;
    width: 100%;
    max-height: 1100px;

    @media screen and (max-width: 600px) {
        background-position: top;
    }
}

.hero {
    height: 100vh;
    max-height: 1100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.overlay {
    background: rgb(243, 243, 243);
    background: linear-gradient(103deg, rgba(243, 243, 243, 1) 36%, rgba(243, 243, 243, 0.7961309523809523) 40%, rgba(243, 243, 243, 0.4992121848739496) 43%, rgba(243, 243, 243, 0) 45%);
    width: 100%;
    height: 100vh;
    max-height: 1100px;
    top: 0;
    left: 0;
    position: fixed;

    @media screen and (max-width: 600px) {
        background: rgb(243, 243, 243);
        opacity: 80%;
    }

}

.heroText {
    color: #040145;
    text-align: left;
    width: 35%;
    margin-top: 200px;
    margin-left: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media screen and (min-width: 1700px) {
        margin-top: 250px;
        width: 38%;
    }


    @media screen and (min-width: 2000px) {
        width: 30%;
        margin-left: 80px;
    }

    @media screen and (max-width: 600px) {
        text-align: left;
        width: 80%;
        align-items: flex-start;
    }
}

.heroText>h1 {
    font-size: 45px;
    font-weight: 400;

    @media screen and (min-width: 1700px) {
        font-size: 55px;
    }

    @media screen and (max-width: 600px) {
        font-size: 30px;
        font-weight: bold;
    }
}

.heroText>h4 {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 300;

    @media screen and (min-width: 1700px) {
        margin-top: 50px;
        font-size: 27px;
    }

    @media screen and (max-width: 600px) {
        font-size: 20px;
    }
}

.styledHeroText {
    font-family: "Montserrat", sans-serif;
    font-size: 22px;

}

.linkToProperties {
    border: 2px solid #040145;
    padding: 10px 35px;
    display: flex;
    justify-content: center;
    border-radius: 0px 8px;
    margin-top: 40px;

    @media screen and (min-width: 1700px) {
        margin-top: 70px;
    }

    @media screen and (max-width: 600px) {
        width: 200px;
    }
}

.linkToProperties>* {
    font-size: 18px;
    font-weight: 300;
    color: #040145;
    text-decoration: none;

    @media screen and (min-width: 1800px) {
        font-size: 25px;
    }

    @media screen and (max-width: 600px) {
        font-size: 20px;
    }
}

.linkToProperties:hover {
    background-color: #040145;

    >* {
        color: #FFFFFF;
    }
}

.page {
    background-color: #F3F3F3;
    position: relative;
    padding-top: 40px;
}

.whoWeAre {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 150px;
    color: #050159;
    font-size: 24px;
    text-align: center;

    @media screen and (min-width: 1800px) {
        font-size: 30px
    }

    @media screen and (max-width: 600px) {
        padding: 0px 10px 50px 10px;
        font-size: 16px;
    }
}

.whoWeAre>h5 {
    margin-bottom: 20px;
    font-size: 12px;

    @media screen and (min-width: 1800px) {
        font-size: 16px;
    }

    @media screen and (max-width: 600px) {
        font-size: 14px;
    }
}

.imageContainer {
    position: relative;
    width: 100%;
    height: 950px;

    @media screen and (min-width: 1800px) {
        height: 1000px;
    }

    @media screen and (max-width: 600px) {
        height: 320px;
    }
}

.image {
    position: absolute;
    background-image: url('../images/meeting_table.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    width: 100%;
    height: 850px;

    @media screen and (min-width: 1800px) {
        height: 1000px;
    }

    @media screen and (max-width: 600px) {
        height: 300px;
    }
}

.advantageSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    text-align: center;
    padding: 50px 0px;
    background-color: #F3F3F3;
    color: #040145;

    @media screen and (min-width: 1800px) {
        font-size: 30px;
        padding: 150px 0px 60px 0px;
    }

    @media screen and (max-width: 600px) {
        padding: 20px 45px;
        font-size: 16px;
        max-width: 100%;
    }
}

.advantageSection>h5 {
    margin-bottom: 20px;
    font-size: 14px;

    @media screen and (min-width: 1800px) {
        font-size: 18px;
    }
}

.advantageWindows {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    padding: 70px 50px;
    color: #040145;

    @media screen and (max-width: 600px) {
        padding: 50px 15px;
    }
}

.advantageWindow {
    padding: 50px;
    margin: 15px;
    width: 260px;
    min-height: 215px;
    text-align: center;
    border: 1px solid #040145;

    @media screen and (min-width: 1800px) {
        width: 350px;
        min-height: 280px;
    }
}

.advantageWindow>h4 {
    padding: 10px 0px;
    font-size: 19px;

    @media screen and (min-width: 1800px) {
        font-size: 27px;
    }
}

.advantageWindow>img {
    width: 30px;
}

.advantageWindow>div {
    font-family: "Montserrat", sans-serif;

    @media screen and (min-width: 1800px) {
        font-size: 22px;
    }
}

.linkToAboutContainer {
    display: flex;
    justify-content: center;
    padding: 30px 0px;
}

.linkToAbout {
    background-color: #040145;
    padding: 15px 10px;
    width: 200px;
    display: flex;
    justify-content: center;
    border-radius: 0px 8px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;


    @media screen and (min-width: 1800px) {
        width: 250px;
    }

    @media screen and (max-width: 600px) {
        margin-right: 0px;
    }
}

.linkToAbout>* {
    font-size: 20px;
    color: #ffffff;
    text-decoration: none;

    @media screen and (min-width: 1800px) {
        font-size: 26px;
    }
}

.linkToAbout:hover {
    background-color: #F3F3F3;
    border: 1px solid #040145;

    >* {
        color: #040145;
    }
}

.featuredProperties {
    position: relative;
    display: flex;
    padding: 100px 40px 150px 40px;
    gap: 30px;
    background-color: #cfcfcf;
    justify-content: center;

    @media screen and (max-width: 600px) {
        flex-direction: column;
        align-items: center;
    }
}

.featuredPropertiesTitle {
    text-align: center;
    margin-top: 100px;
    padding-top: 100px;
    font-size: 18px;
    background-color: #cfcfcf;
    color: #040145;

    @media screen and (min-width: 1800px) {
        font-size: 18px;
    }

    @media screen and (min-width: 1800px) {
        font-size: 18px;
    }

}

.featuredProperty {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 450px;
    background-color: hsl(33, 16%, 73%);

    @media screen and (min-width: 1800px) {
        width: 700px;
    }

    @media screen and (max-width: 600px) {
        width: 300px
    }
}

.featuredProperty>img {
    max-width: 100%;
}

.featuredProperty>div {
    text-align: center;
    font-size: 20px;
    padding: 20px 0px;
    color: #040145;

    @media screen and (min-width: 1800px) {
        font-size: 25px;
    }
}

.testimonials {
    display: flex;
    height: 1500px;
    margin-top: 100px;
    justify-content: center;

    @media screen and (min-width: 1700px) {
        height: 1640px;
    }

    @media screen and (min-width: 1900px) {
        height: 1700px;
    }

    @media screen and (min-width: 2000px) {
        height: 2000px;
    }

    @media screen and (max-width: 800px) {
        flex-direction: column;
        height: auto;
        margin: 0px;
        justify-content: center;
    }
}

.testimonialImages {
    position: absolute;
    display: flex;
    justify-content: space-around;

    @media screen and (max-width: 800px) {
        position: relative;
    }
}

.testimonialImages>img {
    max-width: 47%;

    @media screen and (max-width: 800px) {
        max-width: 50%;
    }
}

.testimonialTitle {
    position: relative;
    margin-top: 42%;
    margin-left: 30%;
    min-width: 55%;
    height: 220px;
    background-color: hsl(33, 16%, 73%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 25px 45px;
    color: #040145;
    font-size: 24px;
    text-align: center;

    @media screen and (min-width: 1800px) {
        font-size: 30px;
    }

    @media screen and (max-width: 800px) {
        margin: 0px;
        font-size: 16px;
    }
}

.testimonialTitle>h5 {
    margin-bottom: 20px;
    font-size: 14px;

    @media screen and (min-width: 1800px) {
        font-size: 18px;
    }
}

.testimonialText {
    margin: 0px 400px 0px 80px;
    color: #040145;
    position: absolute;
    bottom: 100px;
    transition: transform 0.3s ease;

    @media screen and (max-width: 800px) {
        margin: 40px 30px 0px 30px;
        position: relative;
        bottom: 0;
    }
}

.slide-in-out {
    transform: translateX(5%);
}

.testimonialCatchphrase {
    font-size: 26px;

    @media screen and (min-width: 1800px) {
        font-size: 30px;
    }

    @media screen and (max-width: 600px) {
        font-size: 20px;
    }
}

.testimonialBody {
    font-size: 20px;
    margin-top: 25px;
    font-family: "Montserrat", sans-serif;
    max-width: 1300px;

    @media screen and (min-width: 1800px) {
        font-size: 25px;
    }

    @media screen and (max-width: 600px) {
        font-size: 14px;
    }
}

.testimonialClient {
    margin: 25px 0px;
    font-size: 20px;
    font-family: "Montserrat", sans-serif;
    font-style: italic;
    text-align: center;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 1800px) {
        font-size: 25px;
    }

    @media screen and (max-width: 600px) {
        font-size: 14px;
    }
}

.testimonialClient>span>img {
    width: 100px;
    padding: 10px;
}

.arrow {
    position: absolute;
    color: #040145;
    font-size: 80px;
    bottom: 300px;
    right: 150px;

    @media screen and (min-width: 2500px) {
        bottom: 200px;
        right: 400px;
    }

    @media screen and (max-width: 800px) {
        position: relative;
        bottom: 0;
        right: 0;
        text-align: center;
        line-height: 20px;
        font-size: 50px;
        padding-bottom: 30px;
    }
}

.arrow:hover {
    cursor: pointer;
    color: #040145;
}